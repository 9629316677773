import LoginPage from '@risksmart-app/components/AuthPages/Login';
import LogoutPage from '@risksmart-app/components/AuthPages/Logout';
import AccessDeniedPage from '@risksmart-app/components/ErrorPages/AccessDeniedPage';
import AuthErrorPage from '@risksmart-app/components/ErrorPages/AuthErrorPage';
import ErrorPage from '@risksmart-app/components/ErrorPages/ErrorPage';
import InvitationExpiredPage from '@risksmart-app/components/ErrorPages/InvitationExpiredPage';
import OrgNotFoundPage from '@risksmart-app/components/ErrorPages/OrgNotFoundPage';
import UserNotFoundPage from '@risksmart-app/components/ErrorPages/UserNotFoundPage';
import { RouteObject } from 'react-router-dom';
import Home from 'src/pages/Home';
import Questionnaire from 'src/pages/Questionnaire';
import Providers from 'src/providers/Providers';
import ThirdPartyAuth0Context from 'src/providers/ThirdPartyAuth0Context';

import ProtectedLayout from '../layouts/ProtectedLayout';
import ProtectedErrorPage from '../pages/ProtectedErrorPage';
import { accessDeniedUrl, loginUrl, logoutUrl } from './urls';

const routes: RouteObject[] = [
  {
    element: <Providers />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: loginUrl(),
        element: <LoginPage authContext={ThirdPartyAuth0Context} />,
        handle: {
          title: 'Login',
        },
      },
      {
        children: [
          {
            path: '/error',
            element: <ErrorPage />,
            handle: {
              title: 'Error',
            },
          },
          {
            path: '/auth-error',
            element: <AuthErrorPage />,
          },
          {
            path: '/noorg',
            element: <OrgNotFoundPage />,
            handle: {
              title: 'No organisation found',
            },
          },
          {
            path: '/user-not-found',
            element: <UserNotFoundPage />,
            handle: {
              title: 'User not found',
            },
          },
          {
            path: '/invitation-expired',
            element: <InvitationExpiredPage />,
            handle: {
              title: 'Invitation expired',
            },
          },
          {
            path: accessDeniedUrl(),
            element: <AccessDeniedPage />,
            handle: {
              title: 'Access denied',
            },
          },
        ],
      },
      {
        path: logoutUrl(),
        element: (
          <LogoutPage
            loginUrl={() => loginUrl()}
            authContext={ThirdPartyAuth0Context}
          />
        ),
        handle: {
          title: 'Logout',
        },
      },
      {
        element: <ProtectedLayout />,
        path: '/',
        errorElement: <ErrorPage />,
        children: [
          {
            errorElement: <ProtectedErrorPage />,
            children: [
              {
                path: '/',
                element: <Home />,
              },
              {
                path: '/questionnaire/:id',
                element: <Questionnaire />,
              },
            ],
          },
          {
            path: '*',
            element: <div>Not Found</div>,
          },
        ],
      },
    ],
  },
];

export default routes;
