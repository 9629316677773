import { withAuthenticationRequired } from '@auth0/auth0-react';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import Loading from '@risksmart-app/components/Loading';
import { NavMenuProvider } from '@risksmart-app/components/Navigation/NavMenuProvider';
import { NotificationProvider } from '@risksmart-app/components/Notifications/notification-context';
import { isUserInOrganization } from '@risksmart-app/components/utils/authUtils';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { TaxonomyProvider } from 'src/providers/TaxonomyProvider';
import ThirdPartyAuth0Context from 'src/providers/ThirdPartyAuth0Context';

function ProtectedLayout() {
  const { user, isLoading } = useRisksmartUser(ThirdPartyAuth0Context);

  if (isLoading) {
    return <Loading />;
  }

  if (!isUserInOrganization(user)) {
    console.warn('User is not part of an organisation, logging out');

    return <>Logging out</>;
  }

  return (
    <HelmetProvider>
      <TaxonomyProvider>
        <NavMenuProvider>
          <NotificationProvider>
            <>
              <ScrollRestoration />
              <Outlet />
            </>
          </NotificationProvider>
        </NavMenuProvider>
      </TaxonomyProvider>
    </HelmetProvider>
  );
}
export default withAuthenticationRequired(ProtectedLayout, {
  context: ThirdPartyAuth0Context,
});
