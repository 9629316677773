import {
  Alert,
  ContentLayout,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import Breadcrumbs from '@risksmart-app/components/Breadcrumbs';
import useBreadcrumbs from '@risksmart-app/components/hooks/use-breadcrumbs';
import PageHeader from '@risksmart-app/components/PageHeader';
import { ErrorBoundary } from '@sentry/react';
import clsx from 'clsx';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { AuthenticatedAppLayout } from './AuthenticatedAppLayout';

type Meta = {
  /**
   * @description Used to set `window.title` where the page title itself contains sensitive information
   */
  title?: string;
};
interface Props {
  title?: string;
  /**
   * @deprecated Use {@link Props.meta} instead
   */
  pageTitle?: string;
  meta?: Meta;
  actions?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  counter?: string;
  secondary?: React.ReactNode;
  panelContent?: React.ReactNode;
  protected?: boolean;
}

const PageLayout: FC<Props> = ({
  title,
  meta,
  pageTitle,
  secondary,
  panelContent,
  protected: isProtected = true,
  children,
  counter,
  actions,
}) => {
  const { showBreadcrumbs } = useBreadcrumbs();

  const visibleTitle = title || meta?.title || pageTitle;
  const metaTitle = meta?.title || pageTitle || title;
  const page = (
    <>
      <Helmet title={metaTitle} />
      <ContentLayout
        disableOverlap
        header={
          // 84px is the height of the header minus Cloudscape’s default padding (120px total)
          <div className="print:hidden">
            <div
              className={clsx('flex items-center flex-wrap mx-auto px-8', {
                'min-h-[84px]': showBreadcrumbs,
                'min-h-[64px]': !showBreadcrumbs,
              })}
            >
              <div className="block w-full">
                <Breadcrumbs />
                <SpaceBetween size="m">
                  <PageHeader
                    counter={counter}
                    actions={actions}
                    showHelp={false}
                    onShowHelpClick={() => {
                      return true;
                    }}
                  >
                    {visibleTitle}
                  </PageHeader>
                  {secondary}
                </SpaceBetween>
              </div>
            </div>
          </div>
        }
      >
        <div className="py-5 px-8 max-w-full mx-auto">
          <ErrorBoundary
            onError={(error) => console.error(error)}
            fallback={
              <Alert header="Error" type="error">
                An error has occurred
              </Alert>
            }
          >
            <SpaceBetween size="m">{children}</SpaceBetween>
          </ErrorBoundary>
        </div>
      </ContentLayout>
    </>
  );

  return isProtected ? (
    <AuthenticatedAppLayout panelContent={panelContent}>
      {page}
    </AuthenticatedAppLayout>
  ) : (
    <>{children}</>
  );
};

export default PageLayout;
