import { TableProps } from '@cloudscape-design/components-themed';
import Link from '@risksmart-app/components/Link';
import { questionnaireUrl } from 'src/routes/urls';

import { ThirdPartyResponse } from './types';

export function useColumnDefinitions() {
  const columnDefinitions: TableProps<ThirdPartyResponse>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: 'Title',
        cell: (item) => (
          <Link variant="secondary" href={questionnaireUrl(item.Id)}>
            {item.questionnaireTemplateVersion?.parent?.Title} -{' '}
            {item.questionnaireTemplateVersion?.Version}
          </Link>
        ),
        sortingField: 'Title',
        isRowHeader: true,
      },
      {
        id: 'status',
        header: 'Status',
        cell: (item) => item.Status,
      },
      {
        id: 'createdAt',
        header: 'Created At',
        cell: (item) => item.CreatedAtTimestamp,
      },
      {
        id: 'updatedAt',
        header: 'Last modified',
        cell: (item) => item.ModifiedAtTimestamp,
      },
    ];

  return columnDefinitions;
}
