import '@fontsource/sora/400.css';
import '@fontsource/sora/600.css';
import '@fontsource/sora/700.css';
import '@fontsource/sora/800.css';
import './index.css';

import I18NProvider from '@risksmart-app/components/providers/I18nProvider';
import { getEnv } from '@risksmart-app/components/utils/environment';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { App } from './App';

// TODO: lots of other settings to try out later!!!
Sentry.init({
  dsn: 'https://d3ad165179af4213ce936afdf9cfab96@o4505232398745600.ingest.us.sentry.io/4507531538464768',
  environment: getEnv('REACT_APP_ENVIRONMENT'),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <I18NProvider>
      <App />
    </I18NProvider>
  </React.StrictMode>
);
