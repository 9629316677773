import { SpaceBetween } from '@cloudscape-design/components-themed';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import Button from '@risksmart-app/components/Button';
import ConnectedForm, {
  FormTemplateRenderer,
} from '@risksmart-app/components/Form/ConnectedForm/ConnectedForm';
import renderers from '@risksmart-app/components/renderers/registry';
import { FC } from 'react';
import { z } from 'zod';

export interface FormFields {
  Name: string;
  Description: string;
}

export const FormFieldsSchema = z.object({
  Name: z.string().optional(),
  Description: z.string().optional(),
});

interface Props {
  readOnly?: boolean;
  onCancel: () => void;
  onSubmit: (data: FormFields) => void;
  values?: FormFields;
  renderTemplate: FormTemplateRenderer;
  schema: JsonSchema;
  uischema: UISchemaElement;
}

const QuestionnaireForm: FC<Props> = ({
  readOnly,
  onCancel,
  onSubmit,
  values,
  renderTemplate,
  schema,
  uischema,
}) => {
  return (
    <ConnectedForm<FormFields>
      renderTemplate={renderTemplate}
      onSubmit={onSubmit}
      values={values}
      schema={FormFieldsSchema}
      renderButtons={({ formState: { isSubmitting } }) => (
        <SpaceBetween direction="horizontal" size="s">
          <Button variant="primary" disabled={isSubmitting}>
            Submit
          </Button>
          <Button disabled={isSubmitting}>Save</Button>
          <Button
            variant="normal"
            disabled={isSubmitting}
            formAction="none"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </SpaceBetween>
      )}
      renderFormFields={() => (
        <SpaceBetween direction="vertical" size="l">
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={values}
            renderers={renderers}
            readonly={readOnly}
          />
        </SpaceBetween>
      )}
    />
  );
};

export default QuestionnaireForm;
