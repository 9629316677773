import { Container } from '@cloudscape-design/components-themed';
import Loading from '@risksmart-app/components/Loading';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';

import { useGetReponseByIdQuery } from '@/generated/graphql';

import QuestionnaireForm, { FormFields } from './QuestionnaireForm';

interface Props {}

const Page: FC<Props> = () => {
  const Id = useGetGuidParam('id');

  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { data, loading } = useGetReponseByIdQuery({ variables: { Id } });

  if (loading) {
    return <Loading />;
  }

  if (
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Schema
    ) ||
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.UISchema
    )
  ) {
    throw new Error('Schema or UISchema is empty');
  }

  const onSave = async (_data: FormFields) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        navigate('..');
        addNotification({
          type: 'success',
          content: 'Item saved successfully',
        });
        resolve({ foo: 'bar' });
      }, 500);
    });
  };

  return (
    <PageLayout
      title={`${data?.third_party_response_by_pk?.questionnaireTemplateVersion?.parent?.Title} - ${data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Version}`}
    >
      <QuestionnaireForm
        schema={
          data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Schema
        }
        uischema={
          data?.third_party_response_by_pk?.questionnaireTemplateVersion
            ?.UISchema
        }
        renderTemplate={({ buttons, formFields }) => (
          <Container footer={buttons}>{formFields}</Container>
        )}
        values={data?.third_party_response_by_pk?.ResponseData}
        onCancel={() => navigate('..')}
        onSubmit={onSave}
      />
    </PageLayout>
  );
};

export default Page;
